<template>
  <div>
    <div class="card-wrapper">
      <LoadingDonut :class="loadingPage" />
      <Caption class="loading-text">
        กรุณารอสักครู่
      </Caption>
    </div>
  </div>
</template>

<script>
import LoadingDonut from '~/components/commons/bases/loading-donut/loading-donut.vue'
import { Caption } from '~/components/commons/bases/typography'

export default {
  name: 'LoadingModal',
  components: {
    LoadingDonut,
    Caption
  },
  props: {
    loadingPage: {
      type: String,
      required: false,
      default: ''
    },
    sizeModal: {
      type: String,
      required: false,
      default: 's'
    }
  }
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  transition: opacity 0.5s ease;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.loading-text {
  margin-top: 1rem;
  text-align: center;
  color: $maroon-brown;
}
</style>
