







































































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Hamburger from './hamburger.vue'
import AccordionMenuItem from '~/components/commons/organisms/menu-items/accordion-menu-item.vue'
import MenuItem from '~/components/commons/organisms/menu-items/menu-item.vue'
import Button from '~/components/commons/bases/button/button.vue'

export default Vue.extend({
  components: {
    Hamburger,
    AccordionMenuItem,
    Button,
    MenuItem
  },
  data() {
    return {
      isMenuOpen: false
    }
  },
  computed: {
    ...mapGetters('preferred-vehicle', ['preferredVehicle'])
  },
  watch: {
    isMenuOpen: v => {
      const body = document.getElementsByTagName('body')[0]
      if (v) {
        body.style.overflow = 'hidden'
        body.style.position = 'fixed'
      } else {
        body.style.overflow = 'auto'
        body.style.position = 'static'
      }
    }
  },
  mounted() {
    const body = document.getElementsByTagName('body')[0]
    body.style.overflow = 'auto'
    body.style.position = 'static'
  },
  methods: {
    toggle() {
      this.isMenuOpen = !this.isMenuOpen
    },
    close() {
      this.isMenuOpen = false
    },
    handleClick() {
      this.$gtag.event('click', {
        event_action: 'click_from_header',
        event_label: 'loan_request_button'
      })
      this.$analytics.leadFormPage.landforms({
        source: 'header',
        action: 'click loan request button'
      })
    },
    handleClickCal() {
      this.$analytics.vehicleSelectPage.landforms({
        source: 'header',
        action: 'click estimate button'
      })
      this.$gtag.event('click', {
        event_action: 'click_from_header',
        event_label: 'estimate_button'
      })
    }
  }
})
