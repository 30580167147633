








import Vue from 'vue'
export default Vue.extend({
  name: 'QuadItemsLayout',
  props: {
    /**
    enum ['grey', 'white']
     */
    backgroundColor: {
      type: String,
      required: false,
      default: 'white',
      validator: color =>
        [
          'grey',
          'white',
          'yellow',
          'gradient-grey',
          'productCar',
          'productBB',
          'productNMC'
        ].includes(color)
    },
    backgroundImage: {
      type: String,
      required: false,
      default: undefined
    }
  }
})
