export type InterestRates = { [term: string]: number } | RateRule[]
export type InterestRatesByTerm = { [term: string]: number }

export interface VehicleID {
  brand: string
  brandCode: string
  model: string
  year: string
  gear?: string
  engine?: string
  subModel?: string
  vehicleType: VehicleType
}

export type Vehicle = {
  id: VehicleID
  supportedTerms: SupportTerms
  interestRates: InterestRates
  interestRatesByTerm: InterestRatesByTerm
  maxLoanAmounts: MaxLTVs
  isAbleToSelectAL?: boolean
}

export interface Car extends Vehicle {
  id: VehicleID & {
    gear: string
    engine: string
    subModel: string
    vehicleType: CarType
  }
  interestRates: { [term: string]: number }
}

export interface MotorBike extends Vehicle {
  id: VehicleID & { vehicleType: BikeType }
  interestRates: RateRule[]
  interestRatesByTerm: { [term: string]: number }
}

export type VehicleType = CarType | BikeType

export enum Wheeler {
  TWO_WHEELERS = 2,
  FOUR_WHEELERS = 4
}

export type CarType = {
  numberOfWheels: Wheeler.FOUR_WHEELERS
  subType: VehicleSubType.CAR
}

export type BikeType = {
  numberOfWheels: Wheeler.TWO_WHEELERS
  subType: VehicleSubType.BB | VehicleSubType.NMC
}

export enum VehicleSubType {
  CAR = 'car',
  NMC = 'nmc',
  BB = 'bb'
}

export enum LTVOption {
  DEFAULT,
  WITH_GEAR,
  WITH_ENGINE,
  WITH_SUBMODEL
}

export type MaxLTVs = {
  hp: number[]
  al: number[]
}

export type SupportTerms = {
  hp: number[]
  al: number[]
}

export type RateRule = {
  type: VehicleSubType
  minValue: number
  maxValue: number
  supportedAges: number[]
  rates: { [type: string]: { [age: number]: number } }
}

export type MotorbikeIndexed = {
  [brand: string]: {
    [model: string]: {
      [year: string]: MotorBike[]
    }
  }
}

export type CarIndexed = {
  [brand: string]: {
    [model: string]: {
      [year: string]: Car[]
    }
  }
}

export type VehicleIndexed = CarIndexed | MotorbikeIndexed

export type LoanInfo = {
  requestedLoanAmount: number
  term: number
  repaymentAmount: number
}

export interface CarOptionalFields {
  gear?: string
  engine?: string
  subModel?: string
}

export enum LoanType {
  'hp',
  'al'
}

/*
 * 2021-03-25: This is the new type that we are going to use for the new vehicle loan calculator
 */
export type VehicleWithSeries = {
  id: VehicleID
  supportedTerms: SupportTermsWithSeries
  interestRates: InterestRates
  interestRatesByTerm: InterestRatesByTerm
  maxLoanAmounts: MaxLTVsWithSeries
  isAbleToSelectAL?: boolean
}
export interface CarWithSeries extends VehicleWithSeries {
  id: VehicleID & {
    gear: string
    engine: string
    subModel: string
    vehicleType: CarType
  }
  interestRates: { [term: string]: number }
}

export type MaxLTVsWithSeries = {
  hp: number[]
  al: number[]
  rv: number[]
}

export type SupportTermsWithSeries = {
  hp: number[]
  al: number[]
  rv: number[]
}
