




























































































import Vue from 'vue'
import DropdownMenu from './dropdown-menu.vue'
import SideMenu from './side-menu/side-menu.vue'
import Picture from '~/components/commons/bases/picture/picture.vue'
import Button from '~/components/commons/bases/button/button.vue'

export default Vue.extend({
  name: 'HeaderDefault',
  components: {
    Picture,
    SideMenu,
    DropdownMenu,
    Button
  },
  data() {
    return {
      menu: [
        { name: 'หน้าแรก', pathName: '/' },
        {
          name: 'วัตถุประสงค์',
          subMenu: [
            {
              name: 'ปิดหนี้บัตรเครดิต',
              pathName: 'purpose-payoff-credit'
            },
            {
              name: 'ต่อยอดธุรกิจ',
              pathName: 'purpose-business-expansion'
            },
            {
              name: 'ตกแต่งบ้าน',
              pathName: 'purpose-house-renovation'
            },
            {
              name: 'ต้องการค่าเทอม',
              pathName: 'purpose-tuition-fee'
            }
          ]
        },
        { name: 'โปรโมชัน', pathName: 'promotions' },
        { name: 'คำถามที่พบบ่อย', pathName: 'frequently-asked-questions' },
        { name: 'เกี่ยวกับเรา', pathName: 'about-us' },
        { name: 'ติดต่อเรา', pathName: 'contact-us' }
      ],
      isMenuOpen: false
    }
  },
  methods: {
    toggleHamburger() {
      this.isMenuOpen = !this.isMenuOpen
    },
    closeMenu() {
      if (this.isMenuOpen) {
        this.isMenuOpen = false
      }
    },
    handleClick() {
      this.$gtag.event('click', {
        event_action: 'click_from_header',
        event_label: 'loan_request_button'
      })
      this.$analytics.leadFormPage.landforms({
        source: 'header',
        action: 'click loan request button'
      })
    }
  }
})
