<template>
  <RevampHeader v-if="$features('DWB-XXX-REVAMP-2023-Q4-F')" />
  <div v-else class="navigation-bar">
    <nav class="navigation-bar-container">
      <NonMobileNavigationBar v-if="isDesktopViewPort" />
      <MobileNavigationBar v-else />
    </nav>
  </div>
</template>

<script>
import Vue from 'vue'
import MobileNavigationBar from './mobile-navigation-bar.vue'
import NonMobileNavigationBar from './non-mobile-navigation-bar.vue'
import RevampHeader from './header-revamp-version.vue'
import ShellMixin from '~/mixins/shellMixin'

export default Vue.extend({
  components: {
    MobileNavigationBar,
    NonMobileNavigationBar,
    RevampHeader
  },
  mixins: [ShellMixin]
})
</script>

<style lang="scss" scoped>
.navigation-bar {
  box-shadow: 0rem -0.125rem 1.25rem 0rem #c0c0c0;
  position: relative;
  z-index: 1;
  &-container {
    position: sticky;
    display: flex;
    align-items: stretch;
    background: $white;
    top: 0;
    z-index: 999;

    @include mobile {
      height: $header-height-mobile;
      padding: 0 $mobile-edge-offset;
    }

    @include tablet {
      height: $header-height-tablet;
      padding: 0 $tablet-edge-offset;
    }

    @include desktop {
      height: $header-height-desktop;
      padding: 0 $desktop-edge-offset;
    }
  }
}
</style>

<docs>

  ##Examples

  ```jsx
  <Header/>
  ```

</docs>
