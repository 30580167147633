























































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import DropdownMenu from './dropdown-menu.vue'
import Picture from '~/components/commons/bases/picture/picture.vue'
import Button from '~/components/commons/bases/button/button.vue'
import SelectVehicle from '~/components/commons/organisms/modals/select-vehicles-modal/select-vehicles-modal.vue'

export default Vue.extend({
  components: {
    Picture,
    Button,
    SelectVehicle,
    DropdownMenu
  },
  data(): {
    isOpen: boolean
  } {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters('preferred-vehicle', ['preferredVehicle'])
  },
  methods: {
    doSomething(): void {
      this.isOpen = false
      this.$analytics.selectVehicleModal.open({
        source: 'header',
        action: 'click estimate button on header'
      })
    }
  }
})
