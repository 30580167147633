import * as _ from 'lodash'
import { Vehicle, VehicleIndexed } from '~/domains/calculator/types'

export const isValue = (input: any): boolean => input != null && input !== ''

export const enumSorter = (enm): ((a, b) => number) => {
  const orig = Object.values(enm)
  return (a, b): number => {
    const aIndex = orig.indexOf(a)
    const bIndex = orig.indexOf(b)

    return aIndex - bIndex
  }
}

export const composeSorters = (...sorters): ((a, b) => number) => {
  return (a, b): number => {
    for (let i = 0; i < sorters.length; i++) {
      const x = sorters[i](a, b)
      if (x !== 0) {
        return x
      }
    }

    return 0
  }
}

export const sortByAlphabetically = (
  order: 'asc' | 'des' = 'asc'
): ((a, b) => number) => {
  const compareValue = order === 'des' ? 1 : -1
  return (a, b): number => {
    if (a < b) {
      return compareValue
    }
    if (a > b) {
      return -compareValue
    }
    return 0
  }
}

export const sortByPriority = priorities => (a, b): number => {
  const upPriorities = priorities.map(p => p.toUpperCase())

  const aIndex = upPriorities.indexOf(a.toUpperCase())
  const bIndex = upPriorities.indexOf(b.toUpperCase())

  const aHasPriority = aIndex >= 0
  const bHasPriority = bIndex >= 0

  if (bHasPriority && !aHasPriority) {
    return 1
  }

  if (aHasPriority && !bHasPriority) {
    return -1
  }

  if (!aHasPriority && !bHasPriority) {
    return 0
  }

  return aIndex - bIndex
}

export const groupById = data =>
  groupByKeys(data, ['id.brand', 'id.model', 'id.year'])

export const groupByKeys = (data, keys) => {
  const head = keys[0]
  const tail = _.tail(keys)

  if (head) {
    const grouped = _.groupBy(data, head)
    for (const k in grouped) {
      grouped[k] = groupByKeys(grouped[k], tail)
    }
    return grouped
  } else {
    return data
  }
}

export const flatten = (vs: VehicleIndexed): Vehicle[] => {
  return Object.keys(vs).reduce((arr, brand) => {
    return Object.keys(vs[brand]).reduce((arr2, model) => {
      return Object.keys(vs[brand][model]).reduce((arr3, year) => {
        return [...arr3, ...vs[brand][model][year]]
      }, arr2)
    }, arr)
  }, [])
}

export const mask = (input: string): string => {
  const inputList = input.split('')
  const inputLastIndex = inputList.length - 1

  return inputList
    .map((char, index) =>
      index === 0 || index === inputLastIndex ? char : 'x'
    )
    .join('')
}

export const normalizeSpaces = (input: string) =>
  [
    '\u0009',
    '\u000A',
    '\u000B',
    '\u000C',
    '\u000D',
    '\u0020',
    '\u0085',
    '\u00A0',
    '\u1680',
    '\u2000',
    '\u2001',
    '\u2002',
    '\u2003',
    '\u2004',
    '\u2005',
    '\u2006',
    '\u2007',
    '\u2008',
    '\u2009',
    '\u200A',
    '\u2028',
    '\u2029',
    '\u202F',
    '\u205F',
    '\u3000',
    '\u180E',
    '\u200B',
    '\u200C',
    '\u200D',
    '\u2060',
    '\uFEFF'
  ]
    .map(p => new RegExp(p, 'g'))
    .reduce((res, toReplace) => res.replace(toReplace, ' '), input)

export const reduceSpaces = (input: string) => input.replace(/\s+/g, ' ')
