










































import Vue from 'vue'
import { TextBody } from '~/components/commons/bases/typography'

export default Vue.extend({
  name: 'AccordionMenuItem',
  components: {
    TextBody
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    titleTag: {
      type: String,
      required: false,
      default: 'p'
    },
    content: {
      type: String,
      required: false,
      default: null
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    as: {
      type: String,
      required: false,
      default: 'div'
    }
  },
  data() {
    return {
      isActive: this.active || false
    }
  },
  watch: {
    active(active) {
      this.isActive = active
    },
    isActive(isActive) {
      if (isActive) {
        this.open()
      } else {
        this.close()
      }
    }
  },
  mounted() {
    if (this.isActive) {
      this.open()
    }
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive
    },
    open() {
      const item = this.$refs.item as HTMLElement
      item.style.maxHeight = `${item.scrollHeight}px`
      item.style.visibility = 'visible'
    },
    close() {
      const item = this.$refs.item as HTMLElement
      item.style.maxHeight = null
      item.style.visibility = 'hidden'
    }
  }
})
