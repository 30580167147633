















import Vue from 'vue'

export default Vue.extend({
  props: {
    menuLabel: {
      type: String,
      required: true,
      default: null
    },
    subMenus: {
      type: Array,
      required: true,
      default: () => []
    }
  }
})
