var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"overlay",class:[_vm.isMenuOpen ? 'open' : 'close'],on:{"click":_vm.close}}),_vm._v(" "),_c('Hamburger',{attrs:{"is-menu-open":_vm.isMenuOpen},on:{"click":_vm.toggle}}),_vm._v(" "),_c('nav',{staticClass:"sidebar",class:[_vm.isMenuOpen ? 'open' : 'close']},[_c('ul',{staticClass:"sidebar-list-container"},[_c('MenuItem',{attrs:{"label":"หน้าแรก","link":{ name: 'index' }}}),_vm._v(" "),(!_vm.$features('DWB-XXX-REVAMP-2023-Q4-F'))?_c('AccordionMenuItem',{attrs:{"title":"ประเภทรถ","as":"li"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ul',[_c('MenuItem',{attrs:{"label":"รถยนต์","link":{
                name: 'product-car',
                query: { eventscp: 1, click: 'form' }
              },"weight":"400","kind":"sub-item"}}),_vm._v(" "),_c('MenuItem',{attrs:{"label":"บิ๊กไบค์","link":{
                name: 'product-big-bikes',
                query: { eventscp: 4, click: 'form' }
              },"weight":"400","kind":"sub-item"}}),_vm._v(" "),_c('MenuItem',{attrs:{"label":"มอเตอร์ไซค์","link":{
                name: 'product-motorbike',
                query: { eventscp: 8, click: 'form' }
              },"weight":"400","kind":"sub-item"}})],1)]},proxy:true}],null,false,961238993)}):_vm._e(),_vm._v(" "),_c('AccordionMenuItem',{attrs:{"title":"วัตถุประสงค์","as":"li"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ul',[_c('MenuItem',{attrs:{"label":"ปิดหนี้บัตรเครดิต","link":{ name: 'purpose-payoff-credit' },"weight":"400","kind":"sub-item"}}),_vm._v(" "),_c('MenuItem',{attrs:{"label":"ต่อยอดธุรกิจ","link":{ name: 'purpose-business-expansion' },"weight":"400","kind":"sub-item"}}),_vm._v(" "),_c('MenuItem',{attrs:{"label":"ตกแต่งบ้าน","link":{ name: 'purpose-house-renovation' },"weight":"400","kind":"sub-item"}}),_vm._v(" "),_c('MenuItem',{attrs:{"label":"ต้องการค่าเทอม","link":{ name: 'purpose-tuition-fee' },"weight":"400","kind":"sub-item"}})],1)]},proxy:true}])}),_vm._v(" "),_c('MenuItem',{attrs:{"label":"โปรโมชัน","link":{ name: 'promotions' }}}),_vm._v(" "),_c('MenuItem',{attrs:{"label":"คำถามที่พบบ่อย","link":{ name: 'frequently-asked-questions' }}}),_vm._v(" "),_c('MenuItem',{attrs:{"label":"เกี่ยวกับเรา","link":{ name: 'about-us' }}}),_vm._v(" "),_c('MenuItem',{attrs:{"label":"ติดต่อเรา","link":{ name: 'contact-us' }}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$features('DWB-1438-APPEND-CALCULATOR-BUTTON-F')),expression:"$features('DWB-1438-APPEND-CALCULATOR-BUTTON-F')"}],staticClass:"button-calculator",on:{"click":function($event){return _vm.handleClickCal()}}},[_c('Button',{attrs:{"data-test":"button-hamburger-menu-calculator","color-size":"revamp-yellow-secondary","to":{
          name: 'vehicle-select'
        }}},[_vm._v("\n        ประเมินวงเงิน\n      ")])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$features('DWB-1315-BUTTON-LEAD-FORM-F')),expression:"$features('DWB-1315-BUTTON-LEAD-FORM-F')"}],staticClass:"button-lead",on:{"click":function($event){return _vm.handleClick()}}},[_c('Button',{attrs:{"data-test":"button-hamburger-menu-lead","to":{
          name: 'lead-form'
        }}},[_vm._v("\n        ขอสินเชื่อ\n      ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }