











import Vue from 'vue'
import LoadingModal from '~/components/commons/organisms/modals/loading-modal/loading-modal.vue'
import {
  Loading,
  loading
} from '~/components/commons/enhancers/with-loading-type.ts'

export default Vue.extend({
  name: 'WithLoading',
  head() {
    return {
      bodyAttrs: {
        // @ts-ignore
        class: this.isLoading ? 'no-scroll' : ''
      }
    }
  },
  components: {
    LoadingModal
  },
  props: {
    loadingOnCreate: {
      type: Boolean,
      default: true
    }
  },
  data(): { isLoading: boolean } {
    return { isLoading: this.loadingOnCreate }
  },

  provide(): { [loading]: Loading } {
    return {
      [loading]: {
        start: () => {
          // @ts-ignore
          this.isLoading = true
        },
        finish: () => {
          // @ts-ignore
          this.isLoading = false
        }
      }
    }
  }
})
