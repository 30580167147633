
















































































































































































































































































import Vue from 'vue'
import { Caption } from '~/components/commons/bases/typography'

export default Vue.extend({
  components: {
    Caption
  },
  data() {
    return {
      featureTigTok: this.$features('DWB-638-UPDATE-FOOTER-F')
    }
  },
  methods: {
    announcementClick(): void {
      this.addGoogleTag('Click', 'Footer_Section', 'Announcement')
      this.$analytics.announcementPage.land({
        source: 'Footer section',
        action: 'click announcement link'
      })
    },
    addGoogleTag(actionName, categoryName, labelName, value = undefined): void {
      if (value === undefined) {
        this.$ga.event({
          eventAction: actionName,
          eventCategory: categoryName,
          eventLabel: labelName
        })
      } else {
        this.$ga.event({
          eventAction: actionName,
          eventCategory: categoryName,
          eventLabel: labelName,
          value
        })
      }
    }
  }
})
