var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"nav-menu-container"},[_c('div',{staticClass:"revamp-container"},[_c('div',{staticClass:"left-menu-group"},[_c('div',{staticClass:"logo-wrapper",attrs:{"id":"home-page"}},[_c('nuxt-link',{attrs:{"to":"/"}},[_c('Picture',{staticClass:"logo-img",attrs:{"image-file-name":"images/logo_c4c_2020.png","alt":"c4c-logo","width":"100%","heigth":"100%","data-test":"non-mobile-logo"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"list-menu",attrs:{"id":"menu-page"}},[_c('ul',{staticClass:"menu-ul-class"},[_c('li',{staticClass:"nav-menu-item"},[_c('nuxt-link',{key:_vm.$route.fullPath,attrs:{"to":"/"}},[_vm._v("\n              หน้าแรก\n            ")])],1),_vm._v(" "),_c('li',{staticClass:"nav-menu-item"},[_c('DropdownMenu',{key:_vm.$route.fullPath,attrs:{"menu-label":"วัตถุประสงค์","sub-menus":[
                {
                  label: 'ปิดหนี้บัตรเครดิต',
                  linkTo: { name: 'purpose-payoff-credit' }
                },
                {
                  label: 'ต่อยอดธุรกิจ',
                  linkTo: { name: 'purpose-business-expansion' }
                },
                {
                  label: 'ตกแต่งบ้าน',
                  linkTo: { name: 'purpose-house-renovation' }
                },
                {
                  label: 'ต้องการค่าเทอม',
                  linkTo: { name: 'purpose-tuition-fee' }
                }
              ]}})],1),_vm._v(" "),_c('li',{staticClass:"nav-menu-item"},[_c('nuxt-link',{key:_vm.$route.fullPath,attrs:{"to":{ name: 'promotions' }}},[_vm._v("\n              โปรโมชัน\n            ")])],1),_vm._v(" "),_c('li',{staticClass:"nav-menu-item"},[_c('nuxt-link',{key:_vm.$route.fullPath,attrs:{"to":{ name: 'frequently-asked-questions' }}},[_vm._v("\n              คำถามที่พบบ่อย\n            ")])],1),_vm._v(" "),_c('li',{staticClass:"nav-menu-item"},[_c('nuxt-link',{key:_vm.$route.fullPath,attrs:{"to":{ name: 'about-us' }}},[_vm._v("\n              เกี่ยวกับเรา\n            ")])],1),_vm._v(" "),_c('li',{staticClass:"nav-menu-item"},[_c('nuxt-link',{key:_vm.$route.fullPath,attrs:{"to":{ name: 'contact-us' }}},[_vm._v("\n              ติดต่อเรา\n            ")])],1)])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$features('DWB-1315-BUTTON-LEAD-FORM-F')),expression:"$features('DWB-1315-BUTTON-LEAD-FORM-F')"}],staticClass:"header-button-lead",on:{"click":function($event){return _vm.handleClick()}}},[_c('Button',{attrs:{"data-test":"button-header-lead","to":{
            name: 'lead-form'
          }}},[_vm._v("\n          ขอสินเชื่อ\n        ")])],1),_vm._v(" "),_c('SideMenu',{key:_vm.$route.fullPath,staticClass:"display-hamburger"})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }