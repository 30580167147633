






























import Vue from 'vue'
import { TextBody } from '~/components/commons/bases/typography'

export default Vue.extend({
  name: 'MenuItem',
  components: {
    TextBody
  },
  props: {
    label: {
      type: String,
      required: true,
      default: null
    },
    link: {
      type: [String, Object],
      required: true,
      default: null
    },
    weight: {
      type: String,
      required: false,
      default: '500'
    },
    kind: {
      type: String,
      required: false,
      default: 'item',
      validator: val => ['item', 'sub-item'].includes(val)
    },
    goExternal: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
