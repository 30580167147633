




























import Vue from 'vue'
import SideMenu from './side-menu/side-menu.vue'
import Picture from '~/components/commons/bases/picture/picture.vue'
import Button from '~/components/commons/bases/button/button.vue'
import SelectVehicle from '~/components/commons/organisms/modals/select-vehicles-modal/select-vehicles-modal.vue'

export default Vue.extend({
  name: 'MobileNavigationBar',
  components: {
    Picture,
    Button,
    SelectVehicle,
    SideMenu
  },
  data(): {
    isOpen: boolean
  } {
    return {
      isOpen: false
    }
  },
  methods: {
    doSomething(): void {
      this.isOpen = false
      this.$analytics.selectVehicleModal.open({
        source: 'header',
        action: 'click estimate button on header'
      })
    }
  }
})
