










import Vue from 'vue'

export default Vue.extend({
  props: {
    isMenuOpen: {
      type: Boolean,
      required: true,
      default: false
    }
  }
})
