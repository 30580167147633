<template>
  <div ref="myfocus" tabindex="-1" @keyup.esc="onClickButton">
    <transition name="modal">
      <div v-if="isOpen">
        <div class="overlay" @click="onClickButton">
          <div class="modal_v">
            <div class="closemodal">
              <img
                draggable="false"
                data-test-modal="closemodal"
                src="~/assets/icons/close-modal.svg"
                alt="close"
                width="24px"
                height="24px"
                class="no-child"
                @click="onClickButton"
              />
            </div>
            <TitleSmall class="title"
              >เลือกประเภทรถที่ต้องการประเมิน</TitleSmall
            >
            <div class="vehicle-group">
              <nuxt-link
                :to="{
                  path: '/vehicle-search',
                  query: {
                    ...$route.query,
                    'preferred-vehicle': 'car',
                    clickLeadForm: 'n',
                    eventsSearch: 1,
                    subCampaignName: 'CALCULATOR',
                    click: 'form'
                  }
                }"
                class="link-properties"
              >
                <div class="vehicle-box" @click="onClickButtonCar">
                  <img
                    draggable="false"
                    data-test-modal="carmodal"
                    src="~/assets/icons/car-brown-modal.svg"
                    class="imageSize"
                  /><TextBody class="text-container">รถยนต์</TextBody>
                  <div class="img-top">
                    <img
                      draggable="false"
                      src="~/assets/icons/car-yellow-modal.svg"
                      width="160px"
                      height="100px"
                      class="imageSize"
                    />
                    <TextBody class="text-container-hover">รถยนต์</TextBody>
                  </div>
                </div>
              </nuxt-link>

              <nuxt-link
                :to="{
                  path: '/vehicle-search',
                  query: {
                    ...$route.query,
                    'preferred-vehicle': 'bb',
                    clickLeadForm: 'n',
                    eventsSearch: 2,
                    subCampaignName: 'CALCULATOR',
                    click: 'form'
                  }
                }"
                class="link-properties"
              >
                <div class="vehicle-box" @click="onClickButtonBB">
                  <img
                    draggable="false"
                    data-test-modal="bb-modal"
                    src="~/assets/icons/bigbike-brown-modal.svg"
                    class="imageSize"
                  /><TextBody class="text-container">บิ๊กไบค์</TextBody>
                  <div class="img-top">
                    <img
                      draggable="false"
                      src="~/assets/icons/bigbike-yellow-modal.svg"
                      width="160px"
                      height="100px"
                      class="imageSize"
                    />
                    <TextBody class="text-container-hover">บิ๊กไบค์</TextBody>
                  </div>
                </div>
              </nuxt-link>

              <nuxt-link
                :to="{
                  path: '/vehicle-search',
                  query: {
                    ...$route.query,
                    'preferred-vehicle': 'nmc',
                    clickLeadForm: 'n',
                    eventsSearch: 3,
                    subCampaignName: 'CALCULATOR',
                    click: 'form'
                  }
                }"
                class="link-properties"
              >
                <div class="vehicle-box" @click="onClickButtonNmc">
                  <img
                    draggable="false"
                    data-test-modal="nmc-modal"
                    src="~/assets/icons/mtor-brown-modal.svg"
                    class="imageSize"
                  /><TextBody class="text-container">มอเตอร์ไซค์</TextBody>
                  <div class="img-top">
                    <img
                      draggable="false"
                      src="~/assets/icons/mtor-yellow-modal.svg"
                      width="160px"
                      height="100px"
                      class="imageSize"
                    />
                    <TextBody class="text-container-hover"
                      >มอเตอร์ไซค์</TextBody
                    >
                  </div>
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { TitleSmall, TextBody } from '~/components/commons/bases/typography'
export default {
  components: { TitleSmall, TextBody },
  data() {
    return {
      activecar: false,
      activebigbike: false,
      activemtor: false,
      modalclick: false,
      isOpen: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      // ES6 arrow function
      this.$refs.myfocus.focus()
    })
  },
  methods: {
    onClickButton() {
      if (this.modalclick) {
        this.isOpen = true
        this.modalclick = false
      } else {
        this.isOpen = false
        this.$emit('ModalClose', true)
      }
    },
    onClickButtonCar() {
      if (this.modalclick) {
        this.isOpen = true
        this.modalclick = false
      } else {
        // this.$analytics.vehicleSearchPage.landOnVehicle({
        //   source: 'modal select vehicle',
        //   action: 'click car picture in modal'
        // })
        this.$ga.event({
          eventAction: 'click',
          eventCategory: 'vehicle_selection',
          eventLabel: 'car'
        })
        this.$gtag.event('click', {
          event_action: 'select_vehicle',
          event_label: 'car'
        })
        this.$fbq('trackSingle', this.$agency.KA, 'vehicle_selection')
        this.$fbq('trackSingleCustom', this.$agency.KA, 'vehicle_selection', {
          vehicle: 'car'
        })
        this.isOpen = false
        this.$emit('ModalClose', true)
      }
    },
    onClickButtonBB() {
      if (this.modalclick) {
        this.isOpen = true
        this.modalclick = false
      } else {
        // this.$analytics.vehicleSearchPage.landOnVehicle({
        //   source: 'modal select vehicle',
        //   action: 'click bb picture in modal'
        // })
        this.$ga.event({
          eventAction: 'click',
          eventCategory: 'vehicle_selection',
          eventLabel: 'bigbike'
        })
        this.$gtag.event('click', {
          event_action: 'select_vehicle',
          event_label: 'bb'
        })
        this.$fbq('trackSingle', this.$agency.KA, 'vehicle_selection')
        this.$fbq('trackSingleCustom', this.$agency.KA, 'vehicle_selection', {
          vehicle: 'bigbike'
        })
        this.isOpen = false
        this.$emit('ModalClose', true)
      }
    },
    onClickButtonNmc() {
      if (this.modalclick) {
        this.isOpen = true
        this.modalclick = false
      } else {
        // this.$analytics.vehicleSearchPage.landOnVehicle({
        //   source: 'modal select vehicle',
        //   action: 'click nmc picture in modal'
        // })
        this.$ga.event({
          eventAction: 'click',
          eventCategory: 'vehicle_selection',
          eventLabel: 'motorcycle'
        })
        this.$gtag.event('click', {
          event_action: 'select_vehicle',
          event_label: 'mc'
        })
        this.$fbq('trackSingle', this.$agency.KA, 'vehicle_selection')
        this.$fbq('trackSingleCustom', this.$agency.KA, 'vehicle_selection', {
          vehicle: 'motorcycle'
        })
        this.isOpen = false
        this.$emit('ModalClose', true)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.vehicle-box {
  position: relative;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  align-items: center;
  width: 180px;
  height: 180px;
  @include mobile {
    width: 296px;
    height: 72px;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
  }

  border: solid 1px #444444;
}
.vehicle-box-hover {
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  align-items: center;
  width: 180px;
  height: 180px;

  @include mobile {
    width: 296px;
    height: 72px;

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
  }

  border: solid 1px #ffd400;
}
.vehicle-box .img-top {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
}
.vehicle-box:hover .img-top {
  @include desktop {
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-items: center;
    width: 180px;
    height: 180px;
    margin-top: -1px;
    margin-left: -1px;

    border: solid 1px #ffd400;
  }
}

.imageSize {
  width: 160px;
  height: 100px;
  @include mobile {
    width: 96px;
    height: 60px;
    margin-left: 24px;
    margin-right: 24px;
  }
}
.link-properties {
  &:active,
  &:hover,
  &:link,
  &:visited {
    text-decoration: none;
    color: #e60000;
    font-size: 1.125rem;
  }
  .link {
    color: #e60000;
  }
}
.text-container {
  @include tablet {
    padding-top: 0px;
  }
  @include desktop {
    padding-top: 14px;
  }
}
.text-container:hover .img-top {
  color: #ffd400;
  @include tablet {
    padding-top: 0px;
  }
  @include desktop {
    padding-top: 14px;
  }
}
.text-container-hover {
  color: #ffd400;
  @include tablet {
    padding-top: 0px;
  }
  @include desktop {
    padding-top: 14px;
  }
}
.vehicle-group {
  width: 95%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: auto;
  @include mobile {
    flex-direction: column;
    align-items: center;
  }
}

.closemodal {
  display: flex;
  justify-content: flex-end;
  margin-top: -4px;
  margin-bottom: 32px;
}
.title {
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: #444444;
  width: 100%;
  margin-bottom: 32px;
  @include mobile {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 500;
  }
  @include tablet {
    margin-bottom: 35px;
    font-size: 28px;
    font-weight: 500;
  }
  @include desktop {
    margin-bottom: 32px;
    font-size: 28px;
    font-weight: 500;
  }
}
.modal_v {
  width: 720px;
  height: 375px;
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  // box-shadow: 0 2px 8px 3px;
  // transition: all 0.2s ease-in;
  font-family: Helvetica, Arial, sans-serif;
  @include mobile {
    width: 328px;
    height: 440px;
  }
}
.fadeIn-enter {
  opacity: 0;
}

.fadeIn-leave-active {
  opacity: 0;
  transition: all 0.2s step-end;
}

.fadeIn-enter .modal,
.fadeIn-leave-active.modal {
  transform: scale(1.1);
}
button {
  padding: 7px;
  margin-top: 10px;
  background-color: green;
  color: white;
  font-size: 1.1rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  transition: opacity 0.2s ease;
  z-index: 999;
}
</style>
<style>
.hideOverflow {
  overflow: hidden;
}
</style>
