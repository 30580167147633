var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-menu-container"},[_c('div',{staticClass:"left-menu-group"},[_c('div',{staticClass:"logo-wrapper"},[_c('nuxt-link',{attrs:{"to":"/"}},[_c('Picture',{staticClass:"logo-img",attrs:{"image-file-name":"images/logo_c4c_2020.png","alt":"c4c-logo","width":"100%","heigth":"100%","data-test":"non-mobile-logo"}})],1)],1),_vm._v(" "),_c('ul',{staticClass:"menu-ul-class"},[_c('li',{staticClass:"nav-menu-item"},[_c('DropdownMenu',{key:_vm.$route.fullPath,attrs:{"menu-label":"ประเภทรถ","sub-menus":[
            {
              label: 'รถยนต์',
              linkTo: { name: 'product-car', query: { eventscp: 1 } }
            },
            {
              label: 'บิ๊กไบค์',
              linkTo: { name: 'product-big-bikes', query: { eventscp: 4 } }
            },
            {
              label: 'มอเตอร์ไซค์',
              linkTo: { name: 'product-motorbike', query: { eventscp: 8 } }
            }
          ]}})],1),_vm._v(" "),_c('li',{staticClass:"nav-menu-item"},[_c('DropdownMenu',{key:_vm.$route.fullPath,attrs:{"menu-label":"วัตถุประสงค์","sub-menus":[
            {
              label: 'ปิดหนี้บัตรเครดิต',
              linkTo: { name: 'purpose-payoff-credit' }
            },
            {
              label: 'ต่อยอดธุรกิจ',
              linkTo: { name: 'purpose-business-expansion' }
            },
            {
              label: 'ตกแต่งบ้าน',
              linkTo: { name: 'purpose-house-renovation' }
            },
            {
              label: 'ต้องการค่าเทอม',
              linkTo: { name: 'purpose-tuition-fee' }
            }
          ]}})],1),_vm._v(" "),_c('li',{staticClass:"nav-menu-item"},[_c('nuxt-link',{key:_vm.$route.fullPath,attrs:{"to":{ name: 'promotions' }}},[_vm._v("\n          โปรโมชัน\n        ")])],1),_vm._v(" "),_c('li',{staticClass:"nav-menu-item"},[_c('nuxt-link',{key:_vm.$route.fullPath,attrs:{"to":{ name: 'frequently-asked-questions' }}},[_vm._v("\n          คำถามที่พบบ่อย\n        ")])],1),_vm._v(" "),_c('li',{staticClass:"nav-menu-item"},[_c('nuxt-link',{key:_vm.$route.fullPath,attrs:{"to":{ name: 'about-us' }}},[_vm._v("\n          เกี่ยวกับเรา\n        ")])],1),_vm._v(" "),_c('li',{staticClass:"nav-menu-item"},[_c('nuxt-link',{key:_vm.$route.fullPath,attrs:{"to":{ name: 'contact-us' }}},[_vm._v("\n          ติดต่อเรา\n        ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"right-menu-group"},[_c('div',{staticClass:"white_button"},[_c('Button',{attrs:{"to":{
          path: '/lead-form',
          query: Object.assign({}, _vm.$route.query,
            (_vm.preferredVehicle && {
              'preferred-vehicle': _vm.preferredVehicle
            }),
            {clickLeadForm: 'y',
            events: 1,
            subCampaignName: 'EFORM',
            click: 'form'})
        },"kind":"ghost","size":"small"}},[_vm._v("\n        ขอสินเชื่อ\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"yellow_button",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('Button',{attrs:{"data-test":"header-vehicle-search","size":"small"}},[_vm._v("\n        ประเมินวงเงิน\n      ")])],1),_vm._v(" "),(_vm.isOpen)?_c('div',[_c('SelectVehicle',{on:{"ModalClose":_vm.doSomething}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }